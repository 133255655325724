/* Page - About ===== */
#page-about {
  section.masthead {
    max-width: 700px;
    h1 span {
      margin-bottom: 0.5em;
      letter-spacing: 0.15em;
    }
  }
  .our-team-members {
    padding: 5vh 0;
    .our-team-member {
      width: 200px;
      margin: 20px auto;
      text-align: center;
      .our-team-position {
        font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
        letter-spacing: 0.1em;
        font-size: 0.8em;
        text-transform: uppercase;
        padding: 0.5em 0;
      }
    }
  }

  @media (min-width: $breakpoint-mobile) {
    .our-team-members {
      flex-flow: row wrap;
      display: flex;
      justify-content: space-between;
      .our-team-member {
        width: 46%;
      }
    }
  }
  @media (min-width: $breakpoint-tablet) {
    .our-team-members {
      .our-team-member {
        width: 32%;
      }
    }
  }
  @media (min-width: $breakpoint-desktop) {
    .our-team-members {
      .our-team-member {
        width: 18%;
      }
    }
  }
  .our-services {
    background-color: rgba(128, 128, 128, 0.1);
    .our-service {
      h3 {
        margin-bottom: 0.5em;
      }
      ul {
        list-style: none;
        margin: 0 0 2em;
        padding: 0;
        li {
          margin-bottom: 0.5em;
        }
      }
    }
  }

  @media (min-width: $breakpoint-mobile) {
    .our-services {
      padding: 10vh 0;
      h2 {
        text-align: left;
      }
      .our-service {
        width: 50%;
        ul {
          text-align: left;
        }
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    .our-services {
      text-align: center;
    }
  }

  .our-tools {
    max-width: 1100px;
    h2 {
      text-align: center;
      margin: 0 auto;
      max-width: 680px;
    }
    ul {
      list-style: none;
      margin: 4em 0 0;
      padding: 0;
      align-items: center;

      li {
        text-align: center;
        line-height: 120px;
        img,
        svg {
          width: 150px;
          filter: grayscale(100);
        }
        img:hover,
        svg:hover {
          filter: grayscale(0);
          transition: 0.5s;
        }
      }
    }
  }

  @media (min-width: $breakpoint-mobile) {
    .our-tools ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 0 0 260px;
        min-height: 150px;
      }
    }
  }
}
