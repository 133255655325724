.dark-theme {
  background-color: #000;
  color: #eee;

  .header-container,
  .mask {
    background-color: #000;
  }

  .nav-container ul li a:after,
  #page-contact .contact-form .nav-tabs .nav-item span:after {
    background-color: #eee;
  }

  .btn-primary {
    background-color: #eee;
    color: #000;
    border-color: #eee;
  }

  .btn-secondary {
    border-color: #eee;
    &:after {
      background-color: #eee;
    }
  }

  .logo-bottom,
  .logo-top {
    stroke: #ccc;
  }

  .member path {
    fill: #ccc;
  }

  .cta {
    background: #222;
    .arrow,
    .arrow:before,
    .arrow:after {
      background-color: #fff;
    }
  }
  /* SVG Image */
  .darkFill {
    fill: #ccc !important;
  }

  .Collapsible__trigger:before {
    background-color: #fff;
  }
}
