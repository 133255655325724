@font-face {
  font-family: "Proximanova";
  src: url("../fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proximanova Bold";
  src: url("../fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Proximanova SemiBold";
  src: url("../fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Proximanova ExtraBold";
  src: url("../fonts/proximanova-extrabold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  font: 16px "Proximanova", Helvetica, Arial, sans-serif;
  color: #000;
  letter-spacing: 0.05em;
  line-height: 1.5;
  // background: url("../images/logo-bg.png") center center no-repeat fixed;
}

a {
  color: #00b7ff;
}

b {
  font-family: "Proximanova Bold";
}

h1 {
  font-family: "Proximanova ExtraBold", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  margin-top: 0;
  font-size: 2.4em;
  text-transform: uppercase;
  span {
    display: block;
    font-size: 0.6em;
  }
  b {
    /*display: inline-block;
      border-bottom: 3px solid #000;
      line-height: 1.2;
      vertical-align: top;*/
    font-family: "Proximanova SemiBold";
  }
}
h2 {
  font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
  font-size: 2em;
  letter-spacing: 0.05em;
}
h3 {
  font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  letter-spacing: 0.05em;
}
h4 {
  font-family: "Proximanova SemiBold", Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  letter-spacing: 0.05em;
}
p {
  line-height: 2;
  margin: 0 0 2em;
  text-align: justify;
}
.text-center {
  text-align: center;
}
img {
  max-width: 100%;
}
.label-container {
  display: none;
}
@media (max-width: $breakpoint-mobile) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.7em;
  }
}
@media (min-width: $breakpoint-tablet) {
  .label-container {
    display: block;
    max-width: 1200px;
    position: fixed;
    left: 50%;
    top: 0;
    width: 100%;
    transform: translate(-50%, 0);
    .label {
      transform: rotate(-90deg) translateX(-60vh);
      text-transform: uppercase;
      letter-spacing: 4px;
      transform-origin: 0 0;
      font-size: 0.9em;
      font-family: "Proximanova Bold";
      width: 200px;
      text-align: center;
    }
  }
}

/* Layout ===== */
.main-container {
  padding-top: 5em;
  z-index: 2;
  position: relative;
}
section {
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.wrapper {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
}
.col-6 {
  width: 100%;
}
@media (min-width: $breakpoint-mobile) {
  .row {
    flex-flow: row wrap;
    display: flex;
    justify-content: space-between;
  }
  .main-container {
    padding-top: 9em;
  }
  section {
    padding-top: 7.5vh;
    padding-bottom: 7.5vh;
  }
}
@media (min-width: $breakpoint-tablet) {
  .col-6 {
    width: calc(50% - 3em);
  }
}

/* Button ===== */
button:focus {
  outline: none;
}
.btn {
  display: inline-block;
  text-decoration: none;
  font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.9em;
}
.btn-primary {
  background-color: #000;
  min-width: 150px;
  color: #fff;
  border: 1px solid #000;
  padding: 1em;
  letter-spacing: 0.2em;
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #fff;
  color: #000;
}
.btn-secondary {
  /*border-bottom: 2px solid #000;*/
  color: inherit;
  letter-spacing: 0.2em;
  line-height: 2;
  text-indent: 0.2em;
}
.btn-secondary:after {
  display: block;
  content: "";
  height: 2px;
  background-color: #000;
  width: 30%;
  margin: 0 auto;
  -webkit-transition: width 0.5s; /* Safari */
  transition: width 0.5s;
}
.btn-secondary:hover:after {
  width: 100%;
}

/* Header ===== */
.header-container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 1;
  background-color: #fff;
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;
  transform: translateY(-100px);
  .wrapper {
    max-width: 1200px;
  }
  .logo {
    width: 60px;
    float: left;
  }
}
.header-container:after {
  display: block;
  content: "";
  clear: both;
}
.loaded .header-container {
  transform: translateY(0px);
}
.scroll-down .header-container {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: $breakpoint-mobile) {
  .header-container {
    padding: 1.5em 0;
  }
}

/* Navigation ===== */
.nav-container {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      padding: 1em 0;
      a {
        font-family: "Proximanova SemiBold";
        text-transform: uppercase;
        letter-spacing: 0.2em;
        color: inherit;
        text-decoration: none;
        display: inline-block;
        text-indent: 0.2em;
      }
      a:after {
        display: block;
        content: "";
        height: 2px;
        background-color: #000;
        width: 0;
        margin: 0 auto;
        -webkit-transition: width 0.5s; /* Safari */
        transition: width 0.5s;
      }
      a:hover:after,
      a.active:after {
        width: 100%;
      }
    }
  }
}
@media (min-width: $breakpoint-mobile) {
  .nav-container {
    float: right;
    ul li {
      padding-left: 3em;
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .logo {
    display: none;
  }
  .nav-container {
    ul {
      display: flex;
      li {
        flex-grow: 3;
        flex-basis: 0;
      }
    }
  }
}

/* Masthead ===== */
.masthead p {
  text-align: center;
}

/* Footer ===== */
.footer-container {
  text-align: center;
  font-size: 0.85em;
  padding: 3em 0;
}

/* Animation ===== */
.animate-item {
  opacity: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.loaded .in-view .animate-item {
  -webkit-animation-name: fadein;
  animation-name: fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@for $i from 1 through 15 {
  .in-view .animate-item:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.2}s;
  }
}

/* Preloader */
.simplicitic-logo {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -100px;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 11;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: opacity 0.5s;
  z-index: 10;
}
.loaded {
  .simplicitic-logo {
    opacity: 0.05;
    z-index: 1;
  }
  .mask {
    opacity: 0;
    z-index: 0;
  }
}

/* CTA */
.cta {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 0;
  background-color: #eee;
  z-index: 10;
  clear: both;
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;
  transform: translateY(100px);
  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 1px;
    background-color: #000;
    position: relative;
    left: 0;
    margin-left: 2px;
    -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;
    &:before {
      content: "";
      width: 8px;
      height: 1px;
      background-color: #000;
      transform: rotate(35deg);
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
    }
    &:after {
      content: "";
      width: 8px;
      height: 1px;
      background-color: #000;
      transform: rotate(-35deg);
      display: block;
      position: absolute;
      right: 0;
      bottom: -3px;
    }
  }
  .call-title {
    float: left;
    font-size: 1.2em;
    a {
      text-decoration: none;
      color: inherit;
      &:hover .arrow {
        left: 10px;
      }
    }
  }
  .mode {
    font-size: 0.7em;
    background: rgba(128, 128, 128, 0.3);
    display: inline-block;
    padding: 0.2em 0.7em;
    opacity: 0.5;
    margin-right: 0.5em;
    vertical-align: top;
  }
  .clock {
    float: right;
    font-size: 0.9em;
    padding: 0.3em 0;
    span {
      opacity: 0.5;
    }
  }
}
.loaded .cta {
  transform: translateY(0px);
}
.scroll-down .cta {
  opacity: 0;
}
@media (max-width: $breakpoint-tablet) {
  .cta {
    display: none;
  }
}
