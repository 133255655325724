/* Page - Home ===== */
#page-home {
  section.masthead {
    max-width: 760px;
    h1 {
      /*font-size: 3.6em;*/
    }
    p {
      font-size: 1.1em;
    }
  }
  section.portfolio {
    padding-bottom: 0;
    .portfolio-container {
      overflow: hidden;
      margin-bottom: 3em;
      &.clickable {
        cursor: pointer;
      }
      .portfolio-img {
        position: relative;
        overflow: hidden;
        .portfolio-full {
          display: none;
        }
        img {
          display: block;
        }
      }
      .portfolio-content {
        .portfolio-title {
          font-family: "Proximanova Bold";
          font-size: 2em;
          margin-bottom: 0.5em;
        }
      }
    }
    @media (max-width: $breakpoint-tablet) {
      .portfolio-content {
        text-align: center;
      }
      #portfolio-vsi {
        .portfolio-full {
          display: block;
        }
      }
      #portfolio-wisdom-park,
      #portfolio-lakecity {
        .portfolio-thumb {
          display: none;
        }
        .portfolio-thumb-mobile {
          display: block;
        }
      }
    }
    @media (min-width: $breakpoint-tablet) {
      .portfolio-container {
        margin-bottom: 10em;
        .portfolio-img {
          width: 60%;
          float: left;
          .portfolio-full {
            display: block;
          }
          .portfolio-thumb {
            position: absolute;
            z-index: 10;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
          }
          .portfolio-thumb-mobile {
            display: none;
          }
        }
        .portfolio-content {
          width: 30%;
          float: right;
          .portfolio-desc {
            margin-bottom: 2em;
          }
          .btn-secondary:after {
            margin: 0;
          }
        }
        &.active .portfolio-content .btn-secondary:after {
          width: 100%;
        }
      }

      .portfolio-even {
        .portfolio-img {
          float: right;
        }
        .portfolio-content {
          float: left;
        }
      }
      #portfolio-addpointment {
        .portfolio-thumb {
          opacity: 1;
        }
        &.active .portfolio-thumb {
          opacity: 0;
          transform: scale(1.3);
        }
        .portfolio-full {
          opacity: 0;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
        }
        &.active .portfolio-full {
          opacity: 1;
        }
      }
      #portfolio-moodboard {
        .portfolio-thumb {
          opacity: 1;
        }
        &.active .portfolio-thumb {
          opacity: 0;
          transform: scale(1.3);
        }
      }
      #portfolio-natural-orient {
        .portfolio-img {
          // width: 600px;
          // overflow: hidden;
          // position: relative;
          // .portfolio-full {
          //   width: 4800px;
          //   list-style: none;
          //   height: 340px;
          //   margin: 0;
          //   padding: 0;
          //   position: relative;
          //   li {
          //     width: 600px;
          //     float: left;
          //   }
          // }
        }
        &.active .portfolio-thumb {
          transform: translateX(-600px);
        }
      }
      #portfolio-lakecity {
        .portfolio-thumb {
          background-color: rgba(16, 62, 66, 1);
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          text-align: center;
          padding-top: 5em;
          #lakecity-logo {
            width: 200px;
            display: inline-block;
          }
        }
        &.active .portfolio-thumb {
          background-color: rgba(16, 62, 66, 0);
        }
      }
      #portfolio-wisdom-park {
        .portfolio-img {
          height: 340px;
        }
        .portfolio-thumb {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #005c85;
          text-align: center;
          color: #fff;
          z-index: 2;
          h5 {
            font-family: "Proximanova Bold";
            font-size: 2em;
            margin: 4em 0 0;
          }
        }
        .portfolio-cover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #005c85;
          z-index: 1;
          &.ready {
            opacity: 0;
          }
        }
        &.active .portfolio-thumb {
          background-color: rgba(0, 92, 133, 0.8);
        }
      }
      #portfolio-vsi {
        .portfolio-img {
          overflow: hidden;
          position: relative;
          .portfolio-thumb {
            position: absolute;
            background-color: rgba(0, 102, 204, 0.7);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
        &.active .portfolio-thumb {
          transform: translateY(-350px);
        }
      }
      #portfolio-techbond {
        .portfolio-img {
          overflow: hidden;
          position: relative;
          .portfolio-thumb {
            opacity: 1;
            transform: scale(1);
          }
        }
        &.active .portfolio-thumb {
          opacity: 0;
          transform: scale(1.2);
        }
      }
      #portfolio-kakiseni {
        .portfolio-img {
          background-color: #444344;
          height: 340px;
          .portfolio-thumb {
            display: none;
          }
          .kakiseni {
            color: #ffd051;
            font-size: 1.4em;
            font-family: "Proximanova Bold";
            width: 300px;
            text-align: center;
            margin: 3em auto;
            text-transform: uppercase;
            b {
              font-size: 4em;
              display: block;
              line-height: 1.1;
            }
            span {
              font-size: 1.5em;
              display: block;
              line-height: 1;
            }
            div {
              margin: 0;
              letter-spacing: 8px;
            }
          }
        }
        &.active .kakiseni {
          animation: textshine 3s linear infinite;
          background: linear-gradient(90deg, #000, #f7b85c, #000);
          background-size: 200% auto;
          -webkit-background-clip: text;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        @keyframes textshine {
          to {
            background-position: 200%;
          }
        }
      }
    }
  }
}

/* Lakecity Logo */
.st0 {
  fill: #ffffff;
}
.lakecity-logo-stop1 {
  stop-color: #a25437;
}
.lakecity-logo-stop2 {
  stop-color: #ffffff;
}
.lakecity-logo-stop3 {
  stop-color: #a25437;
}
