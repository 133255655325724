.job-posts {
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
}

.Collapsible {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  margin-bottom: 3em;
}
.Collapsible__trigger {
  font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  letter-spacing: 0.05em;
  padding: 0 0 1em 0;
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.5s; /* Safari */
  transition: all 0.5s;
  position: relative;
  &:before {
    width: 0;
    content: "";
    height: 2px;
    background-color: #000;
    display: block;
    position: absolute;
    left: 0;
    top: 0.7em;
    -webkit-transition: width 0.5s; /* Safari */
    transition: width 0.5s;
  }
  &:hover,
  &.is-open {
    padding-left: 40px;
  }
  &:hover:before,
  &.is-open:before {
    width: 20px;
  }
}

.Collapsible__contentInner {
  padding: 0 0 1em;
  h4 {
    margin: 0;
  }
  ul {
    margin-bottom: 2em;
    li {
      margin-bottom: 0.5em;
    }
  }
}
