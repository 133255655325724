/* Page - Contact ===== */
#page-contact {
  .contact-form {
    .nav-tabs {
      list-style: none;
      margin: 3em auto 4em;
      padding: 0;
      flex-flow: row wrap;
      display: flex;
      max-width: 600px;
      justify-content: space-between;
      .nav-item {
        text-align: center;
        width: 33.33%;
        span {
          display: inline-block;
          text-decoration: none;
          font-family: "Proximanova Bold", Helvetica, Arial, sans-serif;
          text-transform: uppercase;
          font-size: 0.9em;
          color: inherit;
          letter-spacing: 0.2em;
          text-indent: 0.2em;
          cursor: pointer;
        }
        span:after {
          display: block;
          content: "";
          height: 2px;
          background-color: #000;
          width: 0;
          margin: 0 auto;
          -webkit-transition: width 0.5s; /* Safari */
          transition: width 0.5s;
        }
        span:hover:after,
        &.react-tabs__tab--selected span:after {
          width: 100%;
        }
      }
    }
    .tab-pane {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
    .tab-pane.react-tabs__tab-panel--selected {
      opacity: 1;
    }
    .form-group {
      position: relative;
      min-height: 60px;
      padding-bottom: 2em;
      label {
        position: absolute;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        top: 0px;
        pointer-events: none;
        left: 0px;
      }
      .form-control {
        border: 0 none;
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        width: 100%;
        padding: 0.5em 0;
        background: transparent;
        outline: none;
        color: inherit;
      }
      &.focus {
        label {
          top: -1.3em;
          font-size: 0.9em;
          opacity: 0.7;
        }
      }
      &.input-resume:not(.focus) {
        label {
          background-color: #fff;
          padding-bottom: 10px;
        }
      }
    }
  }
  .address {
    address {
      font-style: normal;
    }
    span {
      font-size: 0.85em;
      display: block;
      margin-top: 10px;
      svg {
        margin-right: 5px;
      }
    }
  }
  #location-map {
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
}

form {
  div.error-msg,
  div.alert-danger {
    font-size: 0.85em;
    opacity: 0.6;
    font-family: "Proximanova SemiBold";
  }
  .message {
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid rgb(192, 192, 192);
    text-align: center;
    padding: 1em;
    margin-bottom: 1em;
    .icon {
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}

@media (min-width: $breakpoint-mobile) {
  #page-contact .contact-form .nav-tabs .nav-item span {
    line-height: 2;
  }
}
